import React from "react"
import PropTypes from "prop-types"
import ApiKeyList from "./ApiKeyList"
class ApiKeyManager extends React.Component {
  constructor(props) {
    super(props);
    this.heimdall_server_url = props.heimdall_server_url;

    this.state = {
      api_keys: [],
    };

    this.create_key = this.create_key.bind(this);
    this.create_transit_key = this.create_transit_key.bind(this);
    this.create_ticketing_key = this.create_ticketing_key.bind(this);
    this.fetch_all_keys = this.fetch_all_keys.bind(this);

    this.start_edit_mode = this.start_edit_mode.bind(this);
    this.end_edit_mode = this.end_edit_mode.bind(this);

    this.change_name = this.change_name.bind(this);
    this.reset_and_end_edit_mode = this.reset_and_end_edit_mode.bind(this);

    this.save_api_key = this.save_api_key.bind(this);
    this.enable_api_key = this.enable_api_key.bind(this);
    this.disable_api_key = this.disable_api_key.bind(this);
    this.roll_api_key = this.roll_api_key.bind(this);
    this.destroy_api_key = this.destroy_api_key.bind(this);

    this.fetch_all_keys();
  }

  fetch_all_keys(scroll_to_type = null, saved_id = null){
    fetch(this.heimdall_server_url+'api_keys.json', {
      method: 'GET',
      credentials: 'include'
    }).then(function(response) {
      return response.json();
    }).then((function(data){
      this.setState((old_state) => {
        return {
          ...old_state, api_keys: data.map((api_key) => ({
            id: api_key.id,
            key: api_key.api_key,
            previous_api_keys: api_key.previous_api_keys,
            name: api_key.name,
            original_name: api_key.name,
            is_default_transit_test_key: api_key.is_default_transit_test_key,
            is_default_ticketing_test_key: api_key.is_default_ticketing_test_key,
            key_type: api_key.key_type,
            key_environment: api_key.key_environment,
            created_at: api_key.created_at,
            updated_at: api_key.updated_at,
            last_seen_at: api_key.last_seen_at,
            is_disabled: api_key.is_disabled,
            is_connect_api_key: api_key.is_connect_api_key,
            edit_mode: old_state.api_keys.find((old_api_key) => (old_api_key.id === api_key.id))?.edit_mode,
            changed_data: false,
            saved_data: api_key.id == saved_id
          })).filter((api_key) => (!api_key.is_connect_api_key))
        }
      })

      if (scroll_to_type != null) {
        var new_api_key = this.state.api_keys.findLast(api_key => api_key.key_environment === window.environment_mode && api_key.key_type === scroll_to_type)
        document.getElementById(`${new_api_key.key}`).scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
      }

    }).bind(this)).catch(function(error) {
      console.error(error);
    });
  }

  create_transit_key(e){
    e.preventDefault();
    this.create_key("transit")
  }

  create_ticketing_key(e){
    e.preventDefault();
    this.create_key("ticketing")
  }

  create_key(key_type){
    fetch(this.heimdall_server_url+'api_keys.json?key_type='+key_type+'&key_environment='+window.environment_mode, {
      method: 'POST',
      credentials: 'include'
    }).then((function(response) {
      this.fetch_all_keys(key_type)
    }).bind(this)
    ).catch(function(error) {
      console.error(error);
    });
  }

  save_api_key(key){
    var api_key = this.state.api_keys.find((api_key)=>(api_key.key === key))

    fetch(this.heimdall_server_url+'api_keys/'+api_key.id+'.json', {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        api_key: {
          id: api_key.id,
          name: api_key.name
        }
      })
    }).then((function(response) {
      this.fetch_all_keys(null, api_key.id)
    }).bind(this)
    ).catch(function(error) {
      console.error(error);
    });
  }

  roll_api_key(key) {
    var api_key = this.state.api_keys.find((api_key) => (api_key.key === key))

    fetch(this.heimdall_server_url + 'api_keys/' + api_key.id + '/roll_key.json', {
      method: 'PATCH',
      credentials: 'include'
    }).then((function (response) {
      this.fetch_all_keys(null, api_key.id)
    }).bind(this)
    ).catch(function (error) {
      console.error(error);
    });
  }

  enable_api_key(key) {
    var api_key = this.state.api_keys.find((api_key) => (api_key.key === key))

    fetch(this.heimdall_server_url + 'api_keys/' + api_key.id + '/enable.json', {
      method: 'PATCH',
      credentials: 'include'
    }).then((function (response) {
      this.fetch_all_keys()
    }).bind(this)
    ).catch(function (error) {
      console.error(error);
    });
  }

  disable_api_key(key) {
    var api_key = this.state.api_keys.find((api_key) => (api_key.key === key))

    fetch(this.heimdall_server_url + 'api_keys/' + api_key.id + '/disable.json', {
      method: 'PATCH',
      credentials: 'include'
    }).then((function (response) {
      this.fetch_all_keys()
    }).bind(this)
    ).catch(function (error) {
      console.error(error);
    });
  }

  destroy_api_key(key){
    var api_key = this.state.api_keys.find((api_key)=>(api_key.key === key))

    fetch(this.heimdall_server_url+'api_keys/'+api_key.id+'.json', {
      method: 'DELETE',
      credentials: 'include'
    }).then((function(response) {
      this.fetch_all_keys()
    }).bind(this)
    ).catch(function(error) {
      console.error(error);
    });
  }

  set_edit_mode_for(key, value){
    var api_keys = this.state.api_keys.map((api_key)=>((api_key.key !== key)?api_key:{...api_key, edit_mode: value}))
    this.setState((old_state)=>({...old_state, api_keys}))
  }

  start_edit_mode(key){
    this.set_edit_mode_for(key, true)
  }

  end_edit_mode(key){
    this.set_edit_mode_for(key, false)
  }

  change_name(key, value){
    var api_keys = this.state.api_keys.map((api_key)=>((api_key.key !== key)?api_key:{...api_key, name: value, changed_data: true, saved_data: false}))
    this.setState((old_state)=>({...old_state, api_keys}))
  }

  reset_and_end_edit_mode(key){
    var api_keys = this.state.api_keys.map((api_key)=>((api_key.key !== key)?api_key:{...api_key, name: api_key.original_name, edit_mode: false, changed_data: false, saved_data: false}))
    this.setState((old_state)=>({...old_state, api_keys}))
  }

  groupBy(array, key) {
    return array.reduce((result, entry) => {
      var entry_key_value = entry[key]
      if (result[entry_key_value] == undefined) {
        result[entry_key_value] = []
      }
      result[entry_key_value].push(entry)
      return result
    }, {})
  };

  render () {
    var grouped_api_keys = this.groupBy(this.state.api_keys.filter(api_key => api_key.key_environment === window.environment_mode), "key_type")
    return (
      <React.Fragment>
        <h1>{window.environment_mode.toUpperCase()} API-Keys</h1>
        <p>
          You need an API-Key to access the mobilitybox API. The usage is counted per API-Key. A default API-Key is generated for you in order to use examples and so on. We recommend to not publish that one, because it can't be changed. For use in applications please always generate a new one. We recommend to name it accordingly so you can later on associate it with the usage.
        </p>
        <h2>Transit</h2>
        {
          ((grouped_api_keys["transit"] || []).length > 0) ? (
            <div id="transit_api_key_container">
              <a href="#" className="btn btn-primary m-3" onClick={this.create_transit_key}>+ Create new Transit API-Key</a>
              <ApiKeyList entries={grouped_api_keys["transit"]} start_edit_mode={this.start_edit_mode} end_edit_mode={this.end_edit_mode} change_name={this.change_name} reset_and_close={this.reset_and_end_edit_mode} save_api_key={this.save_api_key} roll_api_key={this.roll_api_key} enable_api_key={this.enable_api_key} disable_api_key={this.disable_api_key} destroy_api_key={this.destroy_api_key} />
            </div>
          ) : (
            <p className="my-3">To get generate a API-Key for the Transit Live Environment contact our Support.</p>
          )
        }
        <hr className="m-5"/>
        <h2>Ticketing</h2>
        {
          ((grouped_api_keys["ticketing"] || []).length > 0) ? (
            <div id="ticketing_api_key_container">
              <button className="btn btn-primary m-3" onClick={this.create_ticketing_key}>+ Create new Ticketing API-Key</button>
              <ApiKeyList entries={grouped_api_keys["ticketing"]} start_edit_mode={this.start_edit_mode} end_edit_mode={this.end_edit_mode} change_name={this.change_name} reset_and_close={this.reset_and_end_edit_mode} save_api_key={this.save_api_key} roll_api_key={this.roll_api_key} enable_api_key={this.enable_api_key} disable_api_key={this.disable_api_key} destroy_api_key={this.destroy_api_key} />
            </div>
          ) : (
            <p className="my-3">To get generate a API-Key for the Ticketing Live Environment contact our Support.</p>
          )
        }
        <h2>Connect Management</h2>
        {
          ((grouped_api_keys["connect_management"] || []).length > 0) ? (
            <div id="ticketing_api_key_container">
              <ApiKeyList entries={grouped_api_keys["connect_management"]} start_edit_mode={this.start_edit_mode} end_edit_mode={this.end_edit_mode} change_name={this.change_name} reset_and_close={this.reset_and_end_edit_mode} save_api_key={this.save_api_key} roll_api_key={this.roll_api_key} enable_api_key={this.enable_api_key} disable_api_key={this.disable_api_key} destroy_api_key={this.destroy_api_key} />
            </div>
          ) : (
            <p className="my-3">To get generate a Connect Management API-Key activate Mobilitybox-Connect and create your Distribution Partner.</p>
          )
        }
    </React.Fragment>
    );
  }
}

export default ApiKeyManager
